.comingSoon::before {
  position: absolute;
  content: "";
  background: var(--chakra-colors-red-900);
  height: 28px;
  width: 28px;
  /* Added lines */
  top: 2rem;
  right: -0.5rem;
  transform: rotate(45deg);
}

.comingSoon::after {
  position: absolute;
  content: "Coming Soon";
  top: 7px;
  right: -13px;
  padding: 0.5rem;
  width: 10rem;
  background: var(--chakra-colors-red-900);
  color: white;
  text-align: center;
  font-weight: 700;
  box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2);
}

.newFeature::before {
  position: absolute;
  content: "";
  background: var(--chakra-colors-red-900);
  height: 28px;
  width: 28px;
  /* Added lines */
  top: 2rem;
  right: -0.5rem;
  transform: rotate(45deg);
}

.newFeature::after {
  position: absolute;
  content: "Released 🥳";
  top: 7px;
  right: -13px;
  padding: 0.5rem;
  width: 10rem;
  background: var(--chakra-colors-red-900);
  color: white;
  text-align: center;
  font-weight: 700;
  box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2);
}
